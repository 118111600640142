@import url('https://fonts.googleapis.com/css2?family=Sansita+Swashed:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@100;200;300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@100;200;300;400;500;600;700;800;900&display=swap");


@tailwind base;
@tailwind components;
@tailwind utilities;

body { 
    background: #ffffff;
    font-family: "Be Vietnam Pro", sans-serif;
}

h1 {
    font-family: 'Sansita Swashed', cursive;
    @apply lg:text-6xl text-5xl font-bold text-white
}

.menu-header {
    font-family: 'Sansita Swashed', cursive;
    @apply lg:text-6xl md:text-5xl text-4xl font-bold text-main
}

h2 {
    font-family: 'Sansita Swashed', cursive;
    @apply text-3xl font-bold text-main
}

h3 {
    font-family: "Be Vietnam Pro", sans-serif;
    @apply text-lg md:text-xl font-medium text-slate-900
}

/* h4 {
    font-family: 'Sansita Swashed', cursive;
    @apply text-2xl text-white 
} */

p {
    font-family: "Be Vietnam Pro", sans-serif;
    @apply text-base font-light text-slate-900
}

.label {
    font-family: "Be Vietnam Pro", sans-serif;
    @apply block text-white text-base font-normal 
}

.link {
    @apply text-sm lg:text-lg font-normal cursor-pointer hover:text-main duration-100 ease-in-out;
}

.menu-item-header {
    @apply text-sm md:text-lg text-main text-left font-medium;
}

.menu-item-description {
    font-size: 0.500rem;
    line-height: 1.2rem;
    @apply min-[325px]:text-[0.700rem] min-[437px]:text-xs md:text-sm font-normal;
}

.menu-item-price {
    font-size: 0.500rem;
    line-height: 1rem;
    @apply min-[325px]:text-[0.700rem] min-[437px]:text-xs md:text-sm text-main font-normal;
}

.tab {
    display: inline-block;
    margin-left: 40px;
}

.scroll-margin {
    scroll-margin-top: 5px;
}

ul.leaders {
    max-width: 40em;
    padding: 0;
    overflow-x: hidden;
    list-style: none;
    @apply text-sm md:text-base text-zinc-600; 
}

ul.leaders {
    
    padding: 0;
    overflow-x: hidden;
    list-style: none;
    @apply text-xs md:text-base text-zinc-600; 
}

ul.leaders li:after {
    float: left;
    width: 0;
    white-space: nowrap;
    content:
 ". . . . . . . . . . . . . . . . . . . . "
 ". . . . . . . . . . . . . . . . . . . . "
 ". . . . . . . . . . . . . . . . . . . . "
 ". . . . . . . . . . . . . . . . . . . . "
}

ul.leaders span:first-child {
    padding-right: 0.33em;
    background-color: #ffffff;
    @apply bg-slate-50;
}
ul.leaders span + span {
    float: right;
    padding-left: 0.33em;
    background-color: #ffffff;
    @apply bg-slate-50;
    position: relative;
    z-index: 1
}

#nav {
    @apply mx-auto inset-x-0 h-auto z-40 w-full max-w-none py-4 md:py-8 bg-white text-xl transition-all duration-300 sticky top-0 md:fixed md:top-0 !important;
}

.spacing-container {
    @apply md:px-0 px-3 pt-5 md:pt-36 space-y-24 !important;
}

#hero-header {
    background: linear-gradient(rgba(0, 0, 0, 0.427),rgba(0, 0, 0, 0.4)) ,url('https://res.cloudinary.com/daws02sb6/image/upload/v1694715104/eldhusiru/eldhusiru-header_of0vcb.jpg') no-repeat 50%;
    background-size: cover;
    height: 85vh;
    margin-left: calc(-50vw + 50%);
    width: 100vw;
  }

.order-button {
    @apply bg-main text-white text-2xl font-bold py-4 px-8 rounded-full hover:bg-red-700 ease-in-out duration-300 !important;
}

.nav-order-button {
    @apply bg-main border-2 border-main text-white text-sm lg:text-lg font-bold py-2 px-4 rounded-full hover:bg-red-700 ease-in-out duration-300 !important;
}

.contactus-input {
    @apply bg-white shadow appearance-none border rounded-lg w-full mb-3 md:mb-0 py-2 px-3 leading-tight focus:outline-none !important;
}

.contactus-textarea {
    @apply bg-white block shadow appearance-none h-full border rounded-lg w-full py-2 px-3 leading-tight focus:outline-none !important;
}


/* .v-card .v-card--flat .v-card--link .v-theme--light .v-card--border .v-card--density-default .v-card--variant-elevated {
    left: 0 !important;
} */

textarea {
    resize: none;
  }